<template>
  <v-app>
    <v-container class="d-flex justify-center mb-6">
      <div id="login" class="px-xl-15 px-md-10 px-sm-6 px-3">
        <v-card id="login">
          <v-card-text class="pa-xl-15 pa-md-12 pa-sm-6 pa-8">
            <div class="header">
              <h1 class="text-left">
                <img :src="imagemUrl" width="148" class="hidden-xs-only pt-10" />
              </h1>

              <br v-if="baseURL != 'https://e-gov.agnes.com.br:8080/api/v1'" />

              <h1
                :style="'color: var(--v-primary-base); font-weight: 400 !important; font-size: 20px'"
                class="text-left"
              >
                Faça seu login
              </h1>
            </div>

            <div class="form-login">
              <form @submit.prevent="handleSubmit">
                <div class="pt-12" style="padding-top: 24px !important">
                  <div class="divInput">
                    <v-text-field
                      dense
                      v-model="credentials.username"
                      label="Usuário"
                      :rules="[rules.matchingUser]"
                      id="inputUser"
                      :error-messages="userMessageError"
                      outlined
                      persistent-placeholder
                    />
                  </div>

                  <div class="divInput2">
                    <v-text-field
                      dense
                      v-model="credentials.password"
                      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[rules.required]"
                      :type="show ? 'text' : 'password'"
                      label="Senha"
                      id="inputPassword"
                      outlined
                      persistent-placeholder
                      @click:append="show = !show"
                      :error-messages="passwordMessageError"
                    />
                  </div>
                  <Recaptcha v-if="showRecaptcha" @validate="validate" />

                  <v-btn type="submit" id="btnLogin" color="primary" :loading="loading" :disabled="loading">
                    Entrar
                  </v-btn>

                  <p></p>
                  <p class="forgot-password text-left" style="margin-top: 32px">
                    <router-link
                      v-if="error !== 'Usuário com senha expirada(120 dias).'"
                      style="text-decoration: none; color: #2d5796; font-size: 16px"
                      to="forgot/email"
                      >Esqueceu sua senha e/ou usuário?
                    </router-link>
                    <router-link
                      v-if="error === 'Usuário com senha expirada(120 dias).'"
                      style="text-decoration: none"
                      to="forgot/email"
                      >Cadastrar uma nova senha
                    </router-link>
                  </p>
                  <br />
                  <p style="margin-top: 8px; font-size: 16px">
                    <b>Não possui uma conta?</b><br />
                    Entre em contato pelo e-mail <br />
                    suporte@agnes.com.br
                  </p>
                </div>
              </form>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </v-app>
</template>

<script>
import ThemeMixin from '@/mixins/Theme.vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import axios from 'axios';
import userService from '@/services/userService.js';
import Recaptcha from '@/router/views/recaptcha/Recaptcha';
import Toast from '@/router/views/commons/Toast.vue';
import { eventBus } from '../../../main.js';

window.addEventListener(
  'keydown',
  function (event) {
    if (event.defaultPrevented) {
      return; // Should do nothing if the default action has been cancelled
    }

    var handled = false;
    if (event.key == 'F5') {
      localStorage.removeItem('Error');
    } else if (event.code == 'F5') {
      localStorage.removeItem('Error');
    }

    if (handled) {
      // Suppress "double action" if event handled
      event.preventDefault();
    }
  },
  true,
);
export default {
  name: 'Login',
  mixins: [ThemeMixin],
  components: { Recaptcha, Toast },
  data() {
    return {
      imagemUrl: require(process.env.VUE_APP_LOGO),
      baseURL: process.env.VUE_APP_API,
      loading: false,
      credentials: { username: null, password: null },
      show: false,
      valid: false,
      applicationName: process.env.VUE_APP_NAME,

      applicationSubtitle: process.env.VUE_APP_SUBTITLE,
      mobile: false,
      maxAttempts: 4, //Máximo de 4 tentativas para exibir recaptcha
      attempts: 0,
      showRecaptcha: false,
      validateRecaptcha: false,
      userMessageError: '',
      passwordMessageError: '',
      rules: {
        required: (value) => !!value || 'Campo obrigatório.',
        matchingUser: () => true || this.userMessageError,
        matchingPassword: () => true || this.passwordMessageError,
      },
    };
  },

  computed: {
    ...mapState('user', {
      error: (state) => state.error,
    }),

    ...mapGetters({
      messageLogin: 'user/getMessageLogin',
    }),

    errorToken() {
      return localStorage.getItem('Error');
    },
  },

  mounted() {
    this.getIpAddress();
  },

  methods: {
    ...mapActions('user', ['signIn', 'errorSubmit', 'setMessageLogin', 'token']),

    async handleSubmit() {
      if (this.credentials.username === null || this.credentials.password === null) {
        return;
      }
      this.loading = true;
      let user = this.credentials;
      user.username = user.username.toLowerCase();
      localStorage.removeItem('Error');
      var response = await userService.qtyLoginAttempts(user.username);
      this.attempts = response.data;
      //this.showRecaptcha = this.attempts >= this.maxAttempts;
      if (this.showRecaptcha && !this.validateRecaptcha) {
        localStorage.setItem('Error', 'Valide o recaptcha.');
        this.loading = false;
      } else {
        this.loading = false;
      }

      if ((!this.showRecaptcha && !this.validateRecaptcha) || (this.showRecaptcha && this.validateRecaptcha)) {
        localStorage.removeItem('Error');

        this.signIn(user).then(() => {
          if (this.error === null) {
            this.$router.push('/home');

            this.loading = false;
            this.unsetErrorMessage();
          } else if (this.error === 'Token validation required') {
            this.error = '';
            this.$router.push({
              name: 'ConfirmTokenTfa',
              params: { username: user.username },
            });
            this.loading = false;
            localStorage.removeItem('Error');
          } else {
            this.setErrorMessage();
            if (this.error === 'Sua senha expirou. Passaram 120 dias desde a última alteração') {
              this.showToast(
                'Sua senha expirou. Clique em “Esqueceu sua senha e/ou usuário” para redefinir sua senha',
                'error',
                14,
              );
            }
            this.loading = false;
          }
        });
      }
      this.validateRecaptcha = false;
    },

    async getIpAddress() {
      await axios.get('https://api.ipify.org?format=json').then(({ data }) => {
        localStorage.removeItem('ipAddress');
        localStorage.setItem('ipAddress', data.ip);
        return data.ip;
      });
    },

    validate(wasSuccess) {
      this.validateRecaptcha = wasSuccess;
    },

    setErrorMessage() {
      if (String(this.error).includes('Usuário')) {
        this.setUserMessage(this.error, true);
        this.matchingUser = false;
        this.setPasswordMessage('', false);
        this.matchingPassword = true;
      } else {
        this.setPasswordMessage(this.error, true);
        this.matchingPassword = false;
        this.setUserMessage('', false);
        this.matchingUser = true;
      }
    },

    unsetErrorMessage() {
      this.setUserMessage('', false);
      this.matchingUser = true;
      this.setPasswordMessage('', false);
      this.matchingPassword = true;
    },

    setUserMessage(message, isShow) {
      this.userMessageError = message;
      this.matchingUser = isShow;
    },

    setPasswordMessage(message, isShow) {
      if (message != 'Sua senha expirou. Passaram 120 dias desde a última alteração') {
        this.passwordMessageError = message;
        this.matchingPassword = isShow;
      }
    },

    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },
  },
};
</script>
<style lang="scss" scoped>
#login {
  box-shadow: none !important;
  border: 0px solid #e7e7fa !important;
}
.form-login {
  display: flex;
  flex-direction: column;
  align-items: center;

  > form {
    max-width: 500px;
    width: 100%;
  }
  .img-toast {
    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%) !important;
  }
}
</style>
