<template>
    <VueRecaptcha style="margin-bottom: 32px;" :sitekey="this.siteKey" :loadRecaptchaScript="true" @verify="validate" />
</template>
 <script>
 import VueRecaptcha from 'vue-recaptcha'
 import Validation from '@/services/recaptchaValidate'
 export default {
     components: { VueRecaptcha },
     data() {
         return {
             //TODO: FAZER FUNCIONAR com ENVIROMENT
             //siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC_KEY
             siteKey: '6Le3IeIgAAAAADAr_03dYa5WF7QrEyea1e8i8mS0'
         }
     },
     methods: {
         validate(response) {
             let data = {
                 token: response
             }
             Validation.validate(data).then(result => {
                //TODO: VER SE O BOOLEAN SATISFAZ OU SE DEVE TRABALHAR COM O GOOGLERESPONSE
                 //this.$emit('validate', result.objectResult.success)
                 this.$emit('validate', result)
             }).catch(error => console.log(error))
         }
     }
 }
 </script>