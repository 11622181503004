var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c("v-container", { staticClass: "d-flex justify-center mb-6" }, [
        _c(
          "div",
          {
            staticClass: "px-xl-15 px-md-10 px-sm-6 px-3",
            attrs: { id: "login" },
          },
          [
            _c(
              "v-card",
              { attrs: { id: "login" } },
              [
                _c(
                  "v-card-text",
                  { staticClass: "pa-xl-15 pa-md-12 pa-sm-6 pa-8" },
                  [
                    _c("div", { staticClass: "header" }, [
                      _c("h1", { staticClass: "text-left" }, [
                        _c("img", {
                          staticClass: "hidden-xs-only pt-10",
                          attrs: { src: _vm.imagemUrl, width: "148" },
                        }),
                      ]),
                      _vm.baseURL != "https://e-gov.agnes.com.br:8080/api/v1"
                        ? _c("br")
                        : _vm._e(),
                      _c(
                        "h1",
                        {
                          staticClass: "text-left",
                          style:
                            "color: var(--v-primary-base); font-weight: 400 !important; font-size: 20px",
                        },
                        [_vm._v(" Faça seu login ")]
                      ),
                    ]),
                    _c("div", { staticClass: "form-login" }, [
                      _c(
                        "form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.handleSubmit.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pt-12",
                              staticStyle: { "padding-top": "24px !important" },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "divInput" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      label: "Usuário",
                                      rules: [_vm.rules.matchingUser],
                                      id: "inputUser",
                                      "error-messages": _vm.userMessageError,
                                      outlined: "",
                                      "persistent-placeholder": "",
                                    },
                                    model: {
                                      value: _vm.credentials.username,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "username",
                                          $$v
                                        )
                                      },
                                      expression: "credentials.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "divInput2" },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      "append-icon": _vm.show
                                        ? "mdi-eye"
                                        : "mdi-eye-off",
                                      rules: [_vm.rules.required],
                                      type: _vm.show ? "text" : "password",
                                      label: "Senha",
                                      id: "inputPassword",
                                      outlined: "",
                                      "persistent-placeholder": "",
                                      "error-messages":
                                        _vm.passwordMessageError,
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.show = !_vm.show
                                      },
                                    },
                                    model: {
                                      value: _vm.credentials.password,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.credentials,
                                          "password",
                                          $$v
                                        )
                                      },
                                      expression: "credentials.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm.showRecaptcha
                                ? _c("Recaptcha", {
                                    on: { validate: _vm.validate },
                                  })
                                : _vm._e(),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    type: "submit",
                                    id: "btnLogin",
                                    color: "primary",
                                    loading: _vm.loading,
                                    disabled: _vm.loading,
                                  },
                                },
                                [_vm._v(" Entrar ")]
                              ),
                              _c("p"),
                              _c(
                                "p",
                                {
                                  staticClass: "forgot-password text-left",
                                  staticStyle: { "margin-top": "32px" },
                                },
                                [
                                  _vm.error !==
                                  "Usuário com senha expirada(120 dias)."
                                    ? _c(
                                        "router-link",
                                        {
                                          staticStyle: {
                                            "text-decoration": "none",
                                            color: "#2d5796",
                                            "font-size": "16px",
                                          },
                                          attrs: { to: "forgot/email" },
                                        },
                                        [
                                          _vm._v(
                                            "Esqueceu sua senha e/ou usuário? "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.error ===
                                  "Usuário com senha expirada(120 dias)."
                                    ? _c(
                                        "router-link",
                                        {
                                          staticStyle: {
                                            "text-decoration": "none",
                                          },
                                          attrs: { to: "forgot/email" },
                                        },
                                        [_vm._v("Cadastrar uma nova senha ")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("br"),
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "margin-top": "8px",
                                    "font-size": "16px",
                                  },
                                },
                                [
                                  _c("b", [_vm._v("Não possui uma conta?")]),
                                  _c("br"),
                                  _vm._v(" Entre em contato pelo e-mail "),
                                  _c("br"),
                                  _vm._v(" suporte@agnes.com.br "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }