var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VueRecaptcha", {
    staticStyle: { "margin-bottom": "32px" },
    attrs: { sitekey: this.siteKey, loadRecaptchaScript: true },
    on: { verify: _vm.validate },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }